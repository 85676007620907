import React, { useEffect } from 'react'

function TellerProvider(props) {

  const { institutionId } = props

  const initTeller = (institutionId) => {
    var tellerConnect = window.TellerConnect.setup({
        applicationId: process.env.REACT_APP_TELLER_APP_ID,
        institution: institutionId,
        onInit: function() {
          console.log("Teller Connect has initialized");
        },
        onSuccess: function(enrollment) {
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('providerLinkHandlerSuccess', {'token': enrollment.accessToken})
          }
          console.log("User enrolled successfully", enrollment.accessToken);
        },
        onFailure: function(failure) {
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('providerLinkHandlerFailure', {'message': failure.message})
          }
          console.log("User enrollment failed", failure.message);
        },
        onExit: function() {
          if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('providerLinkHandlerClose')
          }
          console.log("User exited Teller");
        }
      });
      tellerConnect.open();
  }

  useEffect(() => {
    initTeller(institutionId)
  }, [institutionId]);

  return (
    <div></div>
  );
}

export default TellerProvider;
