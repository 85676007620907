
function ManualProvider(props) {

  const { institutionId } = props

  const walletName = institutionId.charAt(0).toUpperCase() + institutionId.slice(1)

  return (
    <div>
      <h1>{`${walletName} Wallet`}</h1>
      <input type="text"></input>
      <button>Connect</button>
    </div>
  )
}

export default ManualProvider;
