import './App.css'
import { useSearchParams } from "react-router-dom"

import ManualProvider from "./providers/ManualProvider"
import PlaidProvider from "./providers/PlaidProvider"
import VezgoProvider from "./providers/VezgoProvider"
import RealizeFiProvider from "./providers/RealizeFiProvider"
import TellerProvider from "./providers/TellerProvider"
import SnaptradeProvider from "./providers/SnaptradeProvider"

function App() {

  const providerMap = {
    'manual': ManualProvider,
    'plaid': PlaidProvider,
    'vezgo': VezgoProvider,
    'realizefi': RealizeFiProvider,
    'teller': TellerProvider,
    'snaptrade': SnaptradeProvider,
  }

  const [searchParams] = useSearchParams()
  const platformId = searchParams.get('platform')
  const institutionId = searchParams.get('institutionId')
  const token = searchParams.get('token')

  console.log("---------------")
  console.log("platform: ", platformId)
  console.log("institutionId: ", institutionId)
  console.log("Token: ", token)

  // Set token for use later
  localStorage.setItem("api_token", token);

  const ProviderComponent = providerMap[platformId]
  if (!ProviderComponent) return (<div>Not found</div>)

  return (
    <div className="App">
      <ProviderComponent institutionId={institutionId}/>
    </div>
  )
}

export default App
