
function RealizeFiProvider() {
  return (
    <div>
      <h1>RealizeFi Provider</h1>
    </div>
  );
}

export default RealizeFiProvider;
