import React, { useEffect, useState, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'

const PlaidProvider = (institutionId) => {
  const [linkToken, setLinkToken] = useState(null)
  const generateToken = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/api_v1/plaid/create_link_token`, {
      method: 'POST',
      headers: new Headers({
        'Authorization': `Bearer ${localStorage.getItem("api_token")}`,
        'Content-Type': 'application/json'
      })
    })
    const data = await response.json()
    setLinkToken(data.link_token)
  }
  const onSuccess = useCallback((public_token, metadata) => {
    // TODO - handle type here
    const link_type = 'brokerage'
    const response = fetch(`${process.env.REACT_APP_BACKEND_URL}/api/api_v1/plaid/create_item`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("api_token")}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ public_token, link_type }),
    })
    // Callback to app here
  }, [])
  useEffect(() => {
    generateToken()
  }, [])

  const config = {
    token: linkToken,
    receivedRedirectUri: window.location.href,
    onSuccess,
  }

  const { open, ready } = usePlaidLink(config)

  if (linkToken !== null && ready) open()
  return <div></div>
}

export default PlaidProvider
