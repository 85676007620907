// import Vezgo from 'vezgo-sdk-js'

function VezgoProvider() {
  return (
    <div>
      <h1>Vezgo Provider</h1>
    </div>
  );
}

export default VezgoProvider;
