
function SnaptradeProvider() {
  return (
    <div>
      <h1>Snaptrade Provider</h1>
    </div>
  );
}

export default SnaptradeProvider;
